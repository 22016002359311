import React from "react";
import { MDXRenderer } from "gatsby-plugin-mdx"
import { GatsbyImage } from "gatsby-plugin-image"

const TeamMember = ({ item: { node: { body, frontmatter: { title: name, featuredImage } } } }) => {
    const featuredImg = featuredImage?.childImageSharp?.gatsbyImageData

    return(
        <div>
            { name.toString().length > 0 ? <h2>{name}</h2> : null }
        
            { featuredImg
                ?
                    <div className="flex flex-wrap">
                    
                        <div className="w-full sm:w-1/2 max-w-sm flex-auto">
                            { featuredImg ? <GatsbyImage image={{ ...featuredImg  }} alt={name} /> : null }
                        </div>
                        
                        <div className="w-full sm:w-1/2 sm:pl-8 flex-auto">
                            <MDXRenderer>{body}</MDXRenderer>
                        </div>
                            
                    </div>
                
                :
                    <MDXRenderer>{body}</MDXRenderer>
            }
        </div>
    )
            
}

export default TeamMember