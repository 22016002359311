import React from "react";
import { graphql } from "gatsby"
import Layout from "../../../components/layout";
import SEO from "../../../components/seo";
import TeamMember from "../../../components/team-member";
import BackButton from '../../../components/back-button.js'
import { groupBy, get } from "lodash/fp";

const Team = ({ data: { allMdx: { edges: teamMembers } } }) => {
  
  const grouped = groupBy(get('node.frontmatter.group'), teamMembers)

  return (
      <Layout>

        <SEO
          keywords={[`erziehungsberatung`, `familienhilfe`, `beratungsangebote`, `pflegeeltern`, `bindung`, `clearing`, `nrw`, `krefeld`, `mülheim`, `ratingen`]}
          title="Angebote"
        />
        <BackButton />

        {
          Object.keys(grouped).map(groupName =>
            (
              <div>
                <h1>{groupName}</h1>
                { grouped[groupName].map(member => <TeamMember key={member.node.frontmatter.slug} item={member} />) }
              </div>
            )
          )
        }
        
      </Layout>
  )
}


export default Team;


export const pageQuery = graphql`
  {
    allMdx(
      filter: {fields: {collection: {eq: "quergedacht-team"}}}
      sort: {fields: frontmatter___order}
    ) {
      edges {
        node {
          body
          frontmatter {
            title
            group
            slug
            teaser
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
